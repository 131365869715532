import "./App.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Authentication from "./components/authentication";
import { LandingPage } from "./pages/landing-page";
import { LegalDetails } from "./pages/legal-details";

function App() {
  return (
    <body>
      <div className="App background">
        <div className="max">
          <Router>
            <Switch>
              <Route path="/admin" component={Authentication} />
              <Route path="/" exact component={LandingPage} />
              <Route path="/legal" component={LegalDetails} />
            </Switch>
          </Router>
        </div>
      </div>
    </body>
  );
}

export default App;
