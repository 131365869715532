import { PropsWithChildren, useMemo } from "react";
import "./content-wrapper.css";

interface Props {
    paddingTop?: number;
    paddingBottom?: number;
    paddingLeft?: number;
    paddingRight?: number;
    className?: string;
    id?: string;
}

export function ContentWrapper(props: PropsWithChildren<Props>) {
    const { children, className = "", paddingBottom, paddingLeft, paddingRight, paddingTop, id } = props;

    const styles: React.CSSProperties = useMemo(() => ({
        paddingTop: paddingTop ? `${paddingTop}rem` : undefined,
        paddingRight: paddingRight ? `${paddingRight}rem` : undefined,
        paddingBottom: paddingBottom ? `${paddingBottom}rem` : undefined,
        paddingLeft: paddingLeft ? `${paddingLeft}rem` : undefined,
    }), [paddingBottom, paddingLeft, paddingRight, paddingTop])

    return (
        <div className={`content-wrapper max-width-wrapper ${className}`} id={id} style={styles}>
            {children}
        </div>
    )
}