import { Auth } from 'aws-amplify';
import * as React from 'react';
import './timeline-delete.css';

export function TimelineDelete () {
    const [id, setId] = React.useState("");

    const updateId = (event : React.ChangeEvent<HTMLInputElement>) => {
        setId(event.target.value);
    }

    const submit = async (event : any) => {
        event.preventDefault();
        console.log("deleting item " + id);
        const token = (await Auth.currentSession()).getIdToken();    
        fetch("https://tlixt0dind.execute-api.eu-central-1.amazonaws.com/dev/timeline/delete/" + id, {
            method: "DELETE",
            headers: {
                "Cognito-Token": token.getJwtToken(),
            }
        })
        //const test1 = await test
    }

    return <div className="admin-form-delete">
        <h2>Delete timeline entry</h2>
        <form onSubmit={submit}>
            <label> ID of entry to delete* </label>
            <input id="id" type="number" className="input-delete" onChange={updateId} placeholder='Numeric ID of row to delete' required/>
            <button className="button" type='submit'>Submit</button>
        </form>
    </div>

}