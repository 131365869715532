import * as React from 'react';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import { Route } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { AdminPage } from '../pages/admin-page';

function Authentication() {
    const request = async () => {
        const body = {
            "Heading" : "Irgendeine Überschrift einer Station 2",
            "Content" : "Some Entry Content 2"
        };
        const token = (await Auth.currentSession()).getIdToken();
        const response = await fetch("https://tlixt0dind.execute-api.eu-central-1.amazonaws.com/dev/timeline/create", {
            method : "POST",  
            body : JSON.stringify(body),
            headers : {
                "Cognito-Token": token.getJwtToken(),
                'Content-Type': 'application/json',
            }      
        })
    };

    return(
        <div>    
            <Route path="/admin/timeline">
                <AdminPage/>
            </Route>
            <AmplifySignOut/>
        </div>
    )
};

export default withAuthenticator(Authentication);
