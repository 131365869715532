import { SkillType } from '../../types/skill-type'
import './rating-provider.css'
interface IRatings {
    [key: string]: RatingLayout
}

const Ratings: IRatings = {
    java: { name: "Java", rating: <a>&#9733;&#9733;&#9733;&#9733;&#9733;</a> },
    spring: { name: "Spring ", rating: <a>&#9733;&#9733;&#9733;&#9733;&#9733;</a> },
    typescript: { name: "TypeScript", rating: <a>&#9733;&#9733;&#9733;&#9733;&#9734;</a> },
    htmlAndCss: { name: "HTML & CSS", rating: <a>&#9733;&#9733;&#9733;&#9733;&#9734;</a> },
    javascript: { name: "JavaScript", rating: <a>&#9733;&#9733;&#9733;&#9734;&#9734;</a> },
    react: { name: "React", rating: <a>&#9733;&#9733;&#9733;&#9734;&#9734;</a> },
    python: { name: "Python", rating: <a>&#9733;&#9733;&#9734;&#9734;&#9734;</a> },
    angular: { name: "Angular", rating: <a>&#9733;&#9733;&#9734;&#9734;&#9734;</a> },
    aws: { name: "AWS", rating: <a>&#9733;&#9733;&#9733;&#9733;&#9734;</a> },
    gcp: { name: "Google Cloud", rating: <a>&#9733;&#9733;&#9733;&#9733;&#9734;</a> },
    ibmcloud: { name: "IBM Cloud", rating: <a>&#9733;&#9733;&#9733;&#9734;&#9734;</a> },
    atlassian: { name: "Atlassian", rating: <a>&#9733;&#9733;&#9733;&#9733;&#9734;</a> },
    camunda: { name: "Camunda", rating: <a>&#9733;&#9733;&#9733;&#9734;&#9734;</a> },
    linux: { name: "Linux", rating: <a>&#9733;&#9733;&#9733;&#9734;&#9734;</a> },
    git: { name: "Git", rating: <a>&#9733;&#9733;&#9733;&#9733;&#9734;</a> },
    bambooAndJenkins: { name: "Bamboo & Jenkins", rating: <a>&#9733;&#9733;&#9733;&#9733;&#9734;</a> },
    jenkins: { name: "Jenkins", rating: <a>&#9733;&#9733;&#9733;&#9733;&#9734;</a> },
    intellij: { name: "Intellij", rating: <a>&#9733;&#9733;&#9733;&#9733;&#9733;</a> },
    vscode: { name: "VS Code", rating: <a>&#9733;&#9733;&#9733;&#9733;&#9733;</a> }

}

export interface RatingLayout {
    name: string;
    rating: JSX.Element;
}

export interface Props {
    ratingType: SkillType;
    className?: string;
}

export function RatingProvider(props: Props) {
    const { ratingType, className } = props;
    const { name, rating } = Ratings[ratingType];

    return (
        <div className="rating-item">
            <div className="skill-name">
                {name}
            </div>
            <div className="rating">
                {rating}
            </div>
        </div>
    )
}