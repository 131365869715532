import React, { useEffect, useState } from "react";
import { TimelineEntry } from "../../types/timeline";
import { Card } from "../card/card";
import { AnimateSharedLayout, motion, AnimatePresence } from "framer-motion";
import "./timetable.css";
import { ContentWrapper } from "../content-wrapper/content-wrapper";
import { CardDetails } from "../card-details/card-details";
import { SkillType } from "../../types/skill-type";
import gsap from "gsap/all";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export function Timetable() {

  const [timelineEntries, setTimelineEntries] = useState<
    [TimelineEntry] | null
  >();

  const [selectedEntry, setSelectedEntry] = useState<TimelineEntry | null>(null)

  useEffect(() => {
    gsap.from('#timeline-heading', {
      duration: 1,
      y: '-20',
      opacity: 0,
      ease: 'ease-in',
      scrollTrigger: {
        trigger: '#timeline-heading',
        start: 'top 80%',
      },
    });
    getTimelineEntries();
  }, []);

  const getTimelineEntries = async () => {
    const response = await fetch(
      "https://tlixt0dind.execute-api.eu-central-1.amazonaws.com/dev/timeline/"
    )
    const body = await response.json();
    const timelineEntries: [TimelineEntry] = body.Items;
    timelineEntries.sort(sortByStartingDate);
    timelineEntries.reverse();
    setTimelineEntries(timelineEntries);
    timelineEntries.map((element, index) => {
      const topCard = 78 + (10 - index);
      const topCardString = "top " + topCard.toString() + "%";
      const topCardContent = 73 + (10 - index);
      const topCardContentString = "top " + topCardContent.toString() + "%";
      gsap.from('.anim-timetable-entry-' + index, {
        duration: 0.25,
        y: '10',
        opacity: 0,
        ease: 'cubic-bezier',
        scrollTrigger: {
          trigger: '.anim-timetable-entry-' + index,
          start: topCardString,
        },
      });


      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: '.anim-timetable-entry-' + index,
          start: topCardContentString,
        }
      });

      tl.from('#anim-timetable-timespan-' + index,
        { opacity: 0, duration: 0.25, y: 15, ease: "ease-in" }
      ).from('#anim-timetable-heading-' + index,
        { opacity: 0, duration: 0.25, y: 15, ease: "ease-in" }
      ).from("#anim-timetable-content-" + index,
        { opacity: 0, duration: 0.25, y: 15, ease: "ease-in" }
      );


    })
  };

  function sortByStartingDate(a: TimelineEntry, b: TimelineEntry) {
    const aMillis = a.TIMESPAN.indexOf("Present") >= 0 ? new Date('01/12/2099').getTime() : new Date('01/' + a.TIMESPAN.split("-")[0]).getTime();
    const bMillis = b.TIMESPAN.indexOf("Present") >= 0 ? new Date('01/12/2099').getTime() : new Date('01/' + b.TIMESPAN.split("-")[0]).getTime();
    return aMillis - bMillis;
  }

  return (
    <ContentWrapper id="timetable">
      <div id="timeline-heading" className="subheading  red section-heading">Timeline</div>
      <div className="timeline">
        <AnimateSharedLayout>
          {timelineEntries?.map((element, index) =>
            <motion.div layout className={`container ${index % 2 ? 'left' : 'right'}`}>
              <Card detailContent={<div><CardDetails
                languages={element.LANGUAGES_AND_FRAMEWORKS === undefined ? [] : element.LANGUAGES_AND_FRAMEWORKS.split(',') as SkillType[]}
                platforms={element.PLATFORMS === undefined ? [] : element.PLATFORMS.split(',') as SkillType[]}
                tools={element.TOOLS === undefined ? [] : element.TOOLS.split(',') as SkillType[]} />
              </div>} className={`card-white pointer anim-timetable-entry-${index}`} index={index.toString()}>
                <motion.div layout className="timespanAndHeading">
                  <div id={`anim-timetable-timespan-${index}`} className="timespan">{element.TIMESPAN} </div>
                  <div id={`anim-timetable-heading-${index}`} className="heading"> {element.HEADING}</div>
                </motion.div>
                <motion.div layout className="timetable-info">
                  <div id={`anim-timetable-content-${index}`} className="description">{element.CONTENT}</div>
                </motion.div>
              </Card>
            </motion.div>
          )}
        </AnimateSharedLayout>
      </div>
    </ContentWrapper>
  );

}