import { SkillType } from "../../types/skill-type";
import { Card } from "../card/card";
import { ContentWrapper } from "../content-wrapper/content-wrapper";
import { Flex } from "../flex/flex";
import { IconProvider } from "../icon-provider/icon-provider";
import { RatingProvider } from "../rating-provider/rating-provider";
import gsap from "gsap/all";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import './skills.css'
import { useEffect } from "react";

gsap.registerPlugin(ScrollTrigger);

export function Skills() {
    const languages: SkillType[] = ["java", "spring", "htmlAndCss", "typescript", "javascript", "react", "angular", "python"];
    const languagesThisSite: SkillType[] = ["nodejs", "javascript", "typescript", "html5", "css3", "react"];
    const platforms: SkillType[] = ["aws", "gcp", "ibmcloud", "atlassian", "linux"];
    const platformsThisSite: SkillType[] = ["awsamplify", "awsapigateway", "awslambda", "awsdynamodb", "awsroute53"];
    const tools: SkillType[] = ["git", "bambooAndJenkins", "intellij", "vscode"];
    const toolsThisSite: SkillType[] = ["git", "vscode"];

    useEffect(() => {
        gsap.from('#skills-heading', {
            duration: 1,
            y: '-20',
            opacity: 0,
            ease: 'ease-in',
            scrollTrigger: {
                trigger: '#skills-heading',
                start: 'top 74%',
            },
        });

        gsap.from('#skills-text', {
            duration: 1,
            y: '40',
            opacity: 0,
            ease: 'ease-in',
            scrollTrigger: {
                trigger: '#skills-text',
                start: 'top 72%',
            },
        });

        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: '#skills-card-1-heading',
                start: 'top 70%',
            }
        });

        tl.from('#skills-card-1-heading',
            { opacity: 0, duration: 0.3, y: 20, ease: "ease-in" }
        ).from("#skills-card-2-heading",
            { opacity: 0, duration: 0.3, y: 20, ease: "ease-in" }
        ).from("#skills-card-3-heading",
            { opacity: 0, duration: 0.3, y: 20, ease: "ease-in" }
        );

        const tlBody = gsap.timeline({
            scrollTrigger: {
                trigger: '#skills-card-1-body',
                start: 'top 68%',
            }
        });

        tlBody.from('#skills-card-1-body',
            { opacity: 0, duration: 0.3, y: 25, ease: "ease-in" }
        ).from("#skills-card-2-body",
            { opacity: 0, duration: 0.3, y: 25, ease: "ease-in" }
        ).from("#skills-card-3-body",
            { opacity: 0, duration: 0.3, y: 25, ease: "ease-in" }
        );

        gsap.from('#skills-this-site', {
            duration: 1,
            y: '40',
            opacity: 0,
            ease: 'ease-in',
            scrollTrigger: {
                trigger: '#skills-this-site',
                start: 'top 72%',
            },
        });

        const tlTechBoxes = gsap.timeline({
            scrollTrigger: {
                trigger: '#skills-this-site',
                start: 'top 70%',
            }
        });

        tlTechBoxes.from('#tech-box-1',
            { opacity: 0, duration: 0.3, y: 25, ease: "ease-in" }
        ).from("#tech-box-2",
            { opacity: 0, duration: 0.3, y: 25, ease: "ease-in" }
        ).from("#tech-box-3",
            { opacity: 0, duration: 0.3, y: 25, ease: "ease-in" }
        );
    }, [])

    return <div className="pos-wrapper">
        <div className="skills-circle-large" />
        <div className="skills-circles" />
        <div className="background-red">
            <ContentWrapper id="skills">
                <div id="skills-heading" className="subheading subheading-white section-heading">Skills</div>
                <h4 id="skills-text">A collection of programming languages, frameworks, platforms and tools I've used and liked the most. I'm more experienced in backend development, but aiming to be a fullstack developer. Also, I'm very interested in cloud platforms like AWS.</h4>
                <div className="skills-wrapper">
                    <Flex container={true}
                        responsiveReverse={false}
                        flexDirection="row"
                        justifyContent="space-between"
                        margin="4rem 0rem 0rem 0rem"
                        gap="3rem"
                    >
                        <Card className="card-red skills-card">
                            <h4 id="skills-card-1-heading" className="card-heading">Languages &amp; Frameworks</h4>
                            <ul id="skills-card-1-body" className="skills-list">
                                {languages.map(element =>
                                    <li> <RatingProvider ratingType={element} /> </li>
                                )}
                            </ul>
                        </Card>
                        <Card className="card-red skills-card">
                            <h4 id="skills-card-2-heading" className="card-heading">Platforms</h4>
                            <ul id="skills-card-2-body" className="skills-list">
                                {platforms.map(element =>
                                    <li> <RatingProvider ratingType={element} /> </li>
                                )}
                            </ul>

                        </Card>
                        <Card className="card-red skills-card">
                            <h4 id="skills-card-3-heading" className="card-heading">Tools &amp; Other</h4>
                            <ul id="skills-card-3-body" className="skills-list">
                                {tools.map(element =>
                                    <li> <RatingProvider ratingType={element} /> </li>
                                )}
                            </ul>

                        </Card>
                    </Flex>

                    <Flex container={true}
                        responsiveReverse={false}
                        flexDirection="row"
                        justifyContent="space-between"
                        margin="4rem 0rem 0rem 0rem"
                        gap="1rem"
                        id="skills-this-site"
                    >
                        <Card className="card-red tech-card">
                            <h4 className="card-heading"> Used for this site</h4>
                            <Flex container={true}
                                responsiveReverse={false}
                                flexDirection="row"
                                justifyContent="space-between"
                            >
                                <div id="tech-box-1" className="tech-box">
                                    <h5 className="card-heading"> Languages &amp; Frameworks</h5>
                                    {languagesThisSite.map(element =>
                                        <IconProvider iconType={element} className="icons-skills" />
                                    )}</div>
                                <div id="tech-box-2" className="tech-box">
                                    <h5 className="card-heading"> Platforms</h5>{platformsThisSite.map(element =>
                                        <IconProvider iconType={element} className="icons-skills" />
                                    )}</div>
                                <div id="tech-box-3" className="tech-box">
                                    <h5 className="card-heading"> Tools &amp; Other</h5>{toolsThisSite.map(element =>
                                        <IconProvider iconType={element} className="icons-skills" />
                                    )}</div>
                            </Flex>
                        </Card>
                    </Flex>

                </div>
            </ContentWrapper>
        </div>
    </div>

}