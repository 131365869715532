import React, { useCallback, useState } from "react";
import { motion } from "framer-motion";
import "./card.css";

interface Props {
    children: React.ReactNode;
    detailContent?: React.ReactElement;
    className?: string;
    index?: string;
}

export function Card(props: Props) {
    const { children, className = "", detailContent, index = "" } = props;
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [rotation, setRotation] = useState<number>(45);

    const toggleDetails = useCallback(() => {
        const newState = !showDetails;
        setShowDetails(newState);
        const extendMarker = document.getElementById("extend-card-" + index);
        console.log("extend marker:", extendMarker);
        if (extendMarker) {
            setRotation(rotation + 45);
            console.log("rotate", rotation.toString())
            extendMarker.style.transform = "rotate(" + rotation.toString() + "deg)";
        }

    }, [showDetails])

    return (
        <motion.div className={`card-pos ${className}`} onClick={toggleDetails}>
            {!!detailContent && <div id={`extend-card-${index}`} className="extend-card-marker">+</div>}
            {children}
            { !!detailContent && <motion.div layout>{showDetails && detailContent}</motion.div>}
        </motion.div>
    );
}
