import React from "react";
import { SvgProvider } from "../components/svg-provider/svg-provider";
import { Flex } from "../components/flex/flex";
import './contact-page.css';

export function ContactPage() {
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [successHeading, setSuccessHeading] = React.useState("");
    const [successBody, setSuccessBody] = React.useState("");

    const updateName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    }

    const updateEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }

    const updateMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(event.target.value);
    }

    const toggleFocusSvg = (id: string) => {
        let svg = document.getElementById(id);
        svg?.classList.contains("red-svg") ? svg?.classList.remove("red-svg") : svg?.classList.add("red-svg");
    }


    const sendMessage = (event: any) => {
        event.preventDefault();
        const body = {
            "name": name,
            "email": email,
            "message": message
        };
        fetch("https://coio0k5elc.execute-api.eu-central-1.amazonaws.com/Dev/sendToMail/", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        }).then(response => {
            if (response.ok) {
                successMessageCallback();
            } else {
                alert("An error occurred when trying to send the message.")
            }
        }).catch(() => {
            alert("An error occurred when trying to send the message.")
        })
    }

    const successMessageCallback = () => {
        setSuccessMessage();
        setName('');
        setEmail('');
        setMessage('');
        let form = document.getElementById("contact-show-form");
        form?.classList.add("hide");
        let successMessage = document.getElementById("contact-success");
        successMessage?.classList.remove("hide");
    }

    const setSuccessMessage = () => {
        /*       
        const nAndE = name.toLowerCase() + email.toLowerCase();
        if (nAndE.includes("triebs") || nAndE.includes("trollini") || nAndE.includes("bynon")) {
            setSuccessHeading("Rämbo du Fresse")
            setSuccessBody("Mach Salesforce zu :)")
        } else if (nAndE.includes("süßgott") || nAndE.includes("götze") || nAndE.includes("goose")) {
            setSuccessHeading("Unnötige Nachricht")
            setSuccessBody("Bitte unterlassen 😡")
        } else if (nAndE.includes("ritter") || nAndE.includes("hartmut") || nAndE.includes("harti") || nAndE.includes("kristian")) {
            setSuccessHeading("Danke Hartmut!")
            setSuccessBody("Deine Nachricht hat mich verzweibelt :)")
        } else if (nAndE.includes("birne") || nAndE.includes("rehbein")) {
            setSuccessHeading("🍐🍐🍐🍐🍐🍐")
            setSuccessBody("danke für die Nachricht (handwerklich top :))")
        } else if (nAndE.includes("dustin") || nAndE.includes("duddl") || nAndE.includes("tenzer")) {
            setSuccessHeading("Duuuuddl")
            setSuccessBody("plis no hack me 🙏")
        } else if (nAndE.includes("remmo") || nAndE.includes("salam")) {
            setSuccessHeading("Ach du bist von Remmo?")
            setSuccessBody("Ah ok")
        } else {
        */
        setSuccessHeading("Thank you!")
        setSuccessBody("I will answer you as soon as possible ✌")
        //}
    }

    const returnToForm = () => {
        let form = document.getElementById("contact-show-form");
        form?.classList.remove("hide");
        let successMessage = document.getElementById("contact-success");
        successMessage?.classList.add("hide");
    }


    return <div className="contact-padding">
        <div className="contact">
            <div>
                <h4 className="subheading red">Say hello</h4>
                <span className="contact-headline"> Send a message</span>
            </div>
            <Flex container={true}
                responsiveReverse={false}
                flexDirection="row"
                justifyContent="space-between"
                margin="2.5rem 0rem 0rem 0rem"
            >

                <div className="contact-form">
                    <div id="contact-show-form">
                        <form onSubmit={sendMessage}>
                            <div className="input-wrapper" >
                                <input id="name" className="contact-input" required placeholder="Name*" value={name}
                                    onChange={updateName} onFocus={() => toggleFocusSvg("name-svg")} onBlur={() => toggleFocusSvg("name-svg")} />
                                <div className="contact-input-icon" >
                                    <SvgProvider id="name-svg" className="svg-transition default-svg" name="person" />
                                </div>
                            </div>
                            <div className="input-wrapper" >
                                <input id="email" className="contact-input" required placeholder="E-Mail*" value={email}
                                    onChange={updateEmail} onFocus={() => toggleFocusSvg("email-svg")} onBlur={() => toggleFocusSvg("email-svg")} />
                                <div className="contact-input-icon" >
                                    <SvgProvider id="email-svg" className="svg-transition default-svg" name="mail" />
                                </div>
                            </div>
                            <div className="input-wrapper" >
                                <textarea id="message" className="large contact-input" required placeholder="Your message*" value={message}
                                    onChange={updateMessage} onFocus={() => toggleFocusSvg("message-svg")} onBlur={() => toggleFocusSvg("message-svg")} />
                                <div className="contact-input-icon" >
                                    <SvgProvider id="message-svg" className="svg-transition default-svg" name="message" />
                                </div>
                            </div>
                            <div className="input-wrapper" >
                                <button id="submit" className="contact-button" type="submit"> Submit <SvgProvider name="arrow" className="submit-icon" /> </button>
                            </div>
                        </form>
                    </div>
                    <div id="contact-success" className="hide">
                        <div className="contact-success-message-heading">{successHeading}</div> <br />
                        <div className="contact-success-message-body">{successBody}</div>
                        <div className="input-wrapper" >
                            <button id="submit" className="contact-button" type="button" onClick={returnToForm}>Go back <SvgProvider name="arrow" className="submit-icon" /> </button>
                        </div>
                    </div>
                </div>
                <div className="contact-data">
                    <div>
                        <div className="contact-data-element">
                            <SvgProvider name="person" className="contact-data-icon default-svg red-svg" />Andreas Lemberger
                        </div>
                        <div className="contact-data-element">
                            <SvgProvider name="mail" className="contact-data-icon default-svg red-svg" />me@andreas-lemberger.de
                        </div>
                    </div>
                    <div>
                        <div className="contact-data-element">
                            <SvgProvider name="phone" className="contact-data-icon default-svg red-svg" />+49 1573 5511157
                        </div>
                        <div className="contact-data-element">
                            <SvgProvider name="location" className="contact-data-icon default-svg red-svg" /> Stuttgart, Germany
                        </div>
                    </div>
                </div>

            </Flex>

            <Flex container={true}
                flexDirection="row"
                justifyContent="space-between"
                margin="1.5rem 0rem 0rem 0rem"
                alignItems="center"
            >
                <div className="contact-socials-flex">
                    <a href="https://www.xing.com/profile/Andreas_Lemberger2/cv" target="_blank" rel="noopener noreferrer">
                        <SvgProvider name="xing" className="contact-socials-icon" />
                    </a>
                    <a href="https://www.xing.com/profile/Andreas_Lemberger2/cv" target="_blank" rel="noopener noreferrer" className="contact-socials-item">Xing </a>
                </div>
                <div className="contact-socials-flex">
                    <a href="https://www.linkedin.com/in/andreas-lemberger-951907205/" target="_blank" rel="noopener noreferrer">
                        <SvgProvider name="linkedIn" className="contact-socials-icon" />
                    </a>
                    <a href="https://www.linkedin.com/in/andreas-lemberger-951907205/" target="_blank" rel="noopener noreferrer" className="contact-socials-item">LinkedIn </a>
                </div>
                <div className="contact-socials-flex">
                    <a href="https://github.com/andreaslemberger" target="_blank" rel="noopener noreferrer">
                        <SvgProvider name="github" className="contact-socials-icon" />
                    </a>
                    <a href="https://github.com/Andi-L" target="_blank" rel="noopener noreferrer" className="contact-socials-item">  Github </a>
                </div>
            </Flex>
        </div>
    </div >
}
