import Logo from "../../assets/alem-logo.svg";
import "./main-header.css";
import { useState, useEffect, useCallback } from "react";
import { ContentWrapper } from "../content-wrapper/content-wrapper";
import { HashLink } from "react-router-hash-link";
import { ContactPage } from "../../pages/contact-page";
import { SvgProvider } from "../svg-provider/svg-provider";
import iconRed from '../../assets/AL_red.svg';
import iconWhite from '../../assets/AL_white.svg';

export function MainHeader() {
  const [toggleBurger, setToggleBurger] = useState(false);
  const [isScrolled, setScrolled] = useState(false);
  const [toggleIcon, setToggleIcon] = useState<any>();

  const toggleBodyStyle = useCallback(async () => {
    window.scrollTo(0, 0);
    const newState = !toggleBurger;
    setToggleBurger(newState);
    if (newState) {
      document.body.classList.add("stop-scrolling");
      document.getElementById("line")?.classList.remove("red-line");
      document.getElementById("icon-logo")?.classList.add("hide");
      setToggleIcon(iconWhite);
    } else {
      document.body.classList.remove("stop-scrolling");
      document.getElementById("line")?.classList.add("red-line");
      document.getElementById("icon-logo")?.classList.remove("hide");
      setToggleIcon(iconRed);
    }
  }, [toggleBurger]);

  const closeOverlayIfOpen = () => {
    if (toggleBurger) {
      setToggleBurger(false);
      document.body.classList.remove("stop-scrolling");
    }
  }

  const checkScrollPos = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = winScroll / height;
    scrolled <= 0 ? setScrolled(false) : setScrolled(true);
  }

  useEffect(() => {
    window.addEventListener('scroll', checkScrollPos);
    setToggleIcon(iconRed);
  }, [])

  //<img src={Logo} alt="logo" className="logo" onClick={closeOverlayIfOpen} />
  return (
    <ContentWrapper id="navbar-wrapper" className={`navbar-wrapper ${isScrolled ? 'scrolled' : ''}`}>
      <div className={`${toggleBurger ? 'toggle' : 'hide'}`}>
        <ContactPage />
      </div>
      <nav>
        <ul>
          <li className="nav-list-item nav-icon">
            <HashLink smooth to="/#top">
              <img src={toggleIcon} alt="logo" className="logo" />
            </HashLink>
          </li>
          <li className="nav-list-item navbar-item">
            <HashLink smooth to="/#about" className="nav-hashlink nav-links">
              <SvgProvider name="person" className="default-svg" />
              <span>About me</span>
            </HashLink>
          </li>
          <li className="nav-list-item navbar-item">
            <HashLink smooth to="/#skills" className="nav-hashlink nav-links">
              <SvgProvider name="brackets" className="default-svg" />
              <a>Skills</a>
            </HashLink>
          </li>
          <li className="nav-list-item navbar-item">
            <HashLink smooth to="/#timetable" className="nav-hashlink nav-links">
              <SvgProvider name="timeline" className="default-svg" />
              <a>Timeline</a>
            </HashLink>
          </li>
        </ul>
        <div
          onClick={toggleBodyStyle}
          className={toggleBurger ? "change burger" : "burger"}
        >
          <div className="bars">
            <div className="bar1"></div>
            <div className="bar2"></div>
          </div>
        </div>
      </nav>
    </ContentWrapper >
  )
}
