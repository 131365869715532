import { TimelineCreate } from "../components/timeline-create/timeline-create";
import { TimelineDelete } from "../components/timeline-delete/timeline-delete";
import { TimelineTable } from "../components/timeline-table/timeline-table";
import { TimelineUpdate } from "../components/timeline-update/timeline-update";

function AdminPage () {
    return <div>
        <TimelineTable/>
        <TimelineCreate/>
        <TimelineUpdate/>
        <TimelineDelete/>
        </div>
}

export {
    AdminPage
}