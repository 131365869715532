import './flex.css';

interface FlexProps {
    responsiveReverse?: boolean,
    children?: any,
    className?: string,
    id?: string,
    container?: boolean,
    /****** Container Props ********/
    flexDirection?: 'row' | 'column',
    justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'initial'
    | 'inherit',
    flexWrap?: 'wrap' | 'nowrap' | 'wrap-reverse',
    alignItems?:
    | 'stretch'
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'baseline'
    | 'initial'
    | 'inherit',
    /****** Child Props ********/
    flexGrow?: number,
    flexShrink?: number,
    flexBasis?: number,
    flex?: string,
    gap?: string
    /****** Common Layout Props ********/
    padding?: string,
    margin?: string,
    width?: string,
    height?: string,
    maxWidth?: string,
    maxHeight?: string
}

export function Flex(props: FlexProps) {
    const className = props.responsiveReverse !== undefined ? (props.responsiveReverse ? 'responsive-reverse' : 'responsive') : undefined;
    return <div
        className={`${className} ${props.className}`}
        id={`${props.id}`}
        style={{
            display: props.container ? 'flex' : 'block',
            justifyContent: props.justifyContent || 'flex-start',
            flexDirection: props.flexDirection || 'row',
            flexGrow: props.flexGrow || 0,
            flexBasis: props.flexBasis || 'auto',
            flexShrink: props.flexShrink || 1,
            flexWrap: props.flexWrap || 'nowrap',
            flex: props.flex || '0 1 auto',
            alignItems: props.alignItems || 'stretch',
            margin: props.margin,
            padding: props.padding,
            width: props.width || 'auto',
            height: props.height || 'auto',
            maxWidth: props.maxWidth || 'none',
            gap: props.gap || 'none'
        }}
    >
        {props.children}
    </div>
}