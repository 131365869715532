import { ContentWrapper } from "../content-wrapper/content-wrapper";
import "./headline.css";
import { SvgProvider } from "../svg-provider/svg-provider";
import { HashLink } from "react-router-hash-link";
import { useEffect } from "react";
import gsap from "gsap";
// import { CSSRulePlugin } from "gsap/CSSRulePlugin";

// gsap.registerPlugin(CSSRulePlugin);


export function Headline() {
  useEffect(() => {

    /*  var rule = CSSRulePlugin.getRule(".headline-animation:after");
      gsap.to(rule, { cssRule: { scaleY: 0 }, duration: 1, y: 20 }); */


    gsap.timeline()
      .from('#gsap-subheading-headline', { opacity: 0, duration: 0.5, y: -30 })
      .from('#gsap-heading-1', { opacity: 0, duration: 0.25, y: 10 })
      .from('#gsap-heading-2', { opacity: 0, duration: 0.25, y: 10 })
      .from('#gsap-heading-3', { opacity: 0, duration: 0.25, y: 10 })
      .from('#gsap-heading-4', { opacity: 0, duration: 0.25, y: 10 })
      .from('#scroll-button', { opacity: 0, duration: 0.25, delay: 0.25 })
      .from('#icon-logo', { opacity: 0, duration: 0.5, delay: 0.25, y: 10 })

  }, [])


  return (
    <ContentWrapper className="landing-page">
      <div className="headline">
        <div id="gsap-subheading-headline" className="subheading red">Andreas Lemberger</div>
        <h1>
          <div id="gsap-heading-1">Hi, my name </div>
          <div id="gsap-heading-2">is Andreas and</div>
          <div id="gsap-heading-3">I am a Computer</div>
          <div id="gsap-heading-4">Scientist</div>
        </h1>
      </div>
      <HashLink id="scroll-button" smooth to="/#about">
        <SvgProvider name="scroll" className="scroll-icon" />
      </HashLink>
    </ContentWrapper>
  );
}
