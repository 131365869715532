import { Footer } from "../components/footer/footer";
import { Headline } from "../components/headline/headline";
import { MainHeader } from "../components/main-header/main-header";
import { Skills } from "../components/skills/skills";
import { Timetable } from "../components/timetable/timetable";
import "./landing-page.css";
import { AboutMe } from "../components/about-me/about-me";
import iconWhite from '../assets/AL_shadow_2.svg';


export function LandingPage() {

  return (
    <div>
      <div id="line" className="red-line">
        <div className="icon-logo-wrapper">
          <img id="icon-logo" src={iconWhite} alt="white-logo"></img>
        </div>
        <MainHeader />
        <Headline />
      </div>
      <AboutMe />
      <Skills />
      <Timetable />
      <Footer />
    </div>
  );
}
