import { SkillType } from "../../types/skill-type";
import { IconProvider } from "../icon-provider/icon-provider";
import './card-details.css';

interface Props {
    languages: SkillType[];
    platforms: SkillType[];
    tools: SkillType[];
    className?: string;
}

export function CardDetails(props: Props) {
    const { languages, platforms, tools, className } = props;
    return (
        <div className={`card-details ${className}`}>
            {languages.length === 0 ? <a /> :
                <div>
                    <h4 className="details-heading">Languages and Frameworks</h4>
                    {languages?.map(element =>
                        <IconProvider iconType={element} className="icons" />
                    )}
                </div>
            }
            {platforms.length === 0 ? <a /> :
                <div>
                    <h4 className="details-heading">Platforms</h4>
                    {platforms?.map(element =>
                        <IconProvider iconType={element} className="icons" />
                    )}
                </div>
            }
            {tools.length === 0 ? <a /> :
                <div>
                    <h4 className="details-heading">Tools</h4>
                    {tools?.map(element =>
                        <IconProvider iconType={element} className="icons" />
                    )}
                </div>
            }
        </div>
    );
}