import java from '../../assets/languages/java.svg';
import angular from '../../assets/languages/angular.svg';
import css3 from '../../assets/languages/css3.svg';
import groovy from '../../assets/languages/groovy.svg';
import html5 from '../../assets/languages/html5.svg';
import javascript from '../../assets/languages/javascript.svg';
import php from '../../assets/languages/php.svg';
import R from '../../assets/languages/R.svg';
import spring from '../../assets/languages/spring.svg';
import python from '../../assets/languages/python.svg';
import webrtc from '../../assets/languages/webrtc.svg';
import typescript from '../../assets/languages/typescript.svg';
import react from '../../assets/languages/react.svg';
import vue from '../../assets/languages/vue.svg';
import atlassian from '../../assets/platforms/atlassian.svg';
import gcp from '../../assets/platforms/gcp.svg';
import aws from '../../assets/platforms/aws.svg';
import camunda from '../../assets/platforms/camunda.svg';
import ibm from '../../assets/platforms/ibm.svg';
import ibmcloud from '../../assets/platforms/ibmcloud.svg';
import kafka from '../../assets/platforms/kafka.svg';
import linux from '../../assets/platforms/linux.svg';
import mysql from '../../assets/platforms/mysql.svg';
import oracledb from '../../assets/platforms/oracledb.svg';
import tomcat from '../../assets/platforms/tomcat.svg';
import awsamplify from '../../assets/platforms/awsamplify.svg';
import nodejs from '../../assets/languages/nodejs.svg';
import awsapigateway from '../../assets/platforms/awsapigateway.svg';
import awslambda from '../../assets/platforms/awslambda.svg';
import awsroute53 from '../../assets/platforms/awsroute53.svg';
import awsdynamodb from '../../assets/platforms/awsdynamodb.svg';
import bamboo from '../../assets/tools/bamboo.svg';
import eclipse from '../../assets/tools/eclipse.svg';
import git from '../../assets/tools/git.svg';
import intellij from '../../assets/tools/intellij.svg';
import jenkins from '../../assets/tools/jenkins.svg';
import netbeans from '../../assets/tools/netbeans.svg';
import svn from '../../assets/tools/svn.svg';
import vscode from '../../assets/tools/vscode.svg';
import xing from '../../assets/social/xing.svg';
import linkedin from '../../assets/social/linkedin.svg';
import github from '../../assets/social/github.svg';
import { SkillType } from '../../types/skill-type';


interface ISkillIcons {
    [key: string]: SkillIconLayout;
}

export const SkillIcons: ISkillIcons = {
    java: { icon: java, alt: "java", hover: "Java" },
    angular: { icon: angular, alt: "angular", hover: "Angular" },
    css3: { icon: css3, alt: "css3", hover: "CSS3" },
    groovy: { icon: groovy, alt: "groovy", hover: "Groovy" },
    html5: { icon: html5, alt: "html5", hover: "HTML5" },
    javascript: { icon: javascript, alt: "javascript", hover: "javascript" },
    php: { icon: php, alt: "php", hover: "PHP" },
    R: { icon: R, alt: "R", hover: "R" },
    spring: { icon: spring, alt: "spring", hover: "spring" },
    python: { icon: python, alt: "python", hover: "python" },
    typescript: { icon: typescript, alt: "typescript", hover: "typescript" },
    webrtc: { icon: webrtc, alt: "webrtc", hover: "WebRTC" },
    vue: { icon: vue, alt: "vue", hover: "Vue" },
    react: { icon: react, alt: "react", hover: "React" },
    atlassian: { icon: atlassian, alt: "atlassian", hover: "Atlassian Toolchain" },
    aws: { icon: aws, alt: "aws", hover: "AWS" },
    gcp: { icon: gcp, alt: "gcp", hover: "Google Cloud Platform" },
    camunda: { icon: camunda, alt: "camunda", hover: "Camunda" },
    ibmcloud: { icon: ibmcloud, alt: "ibmcloud", hover: "IBM Cloud" },
    ibm: { icon: ibm, alt: "ibm", hover: "IBM" },
    kafka: { icon: kafka, alt: "kafka", hover: "Apache Kafka" },
    linux: { icon: linux, alt: "linux", hover: "linux" },
    mysql: { icon: mysql, alt: "mysql", hover: "MySQL" },
    oracledb: { icon: oracledb, alt: "oracledb", hover: "Oracle Database" },
    tomcat: { icon: tomcat, alt: "tomcat", hover: "Apache Tomcat" },
    awsamplify: { icon: awsamplify, alt: "awsamplify", hover: "AWS Amplify" },
    nodejs: { icon: nodejs, alt: "nodejs", hover: "nodejs" },
    awsapigateway: { icon: awsapigateway, alt: "awsapigateway", hover: "AWS API Gateway" },
    awslambda: { icon: awslambda, alt: "awslambda", hover: "AWS Lambda" },
    awsroute53: { icon: awsroute53, alt: "awsroute53", hover: "AWS Route53" },
    awsdynamodb: { icon: awsdynamodb, alt: "awsdynamodb", hover: "AWS DynamoDB" },
    bamboo: { icon: bamboo, alt: "bamboo", hover: "bamboo" },
    eclipse: { icon: eclipse, alt: "eclipse", hover: "Eclipse" },
    git: { icon: git, alt: "git", hover: "git" },
    intellij: { icon: intellij, alt: "intellij", hover: "Intellij IDEA" },
    jenkins: { icon: jenkins, alt: "jenkins", hover: "jenkins" },
    netbeans: { icon: netbeans, alt: "netbeans", hover: "NetBeans" },
    svn: { icon: svn, alt: "svn", hover: "Subversion" },
    vscode: { icon: vscode, alt: "vscode", hover: "Visual Studio Code" },
    xing: { icon: xing, alt: "xing", hover: "Xing" },
    linkedin: { icon: linkedin, alt: "linkedin", hover: "linkedin" },
    github: { icon: github, alt: "github", hover: "github" }
}

export interface SkillIconLayout {
    icon: string;
    alt: string;
    hover: string;
}

export interface Props {
    iconType: SkillType,
    className?: string;
}

export function IconProvider(props: Props) {
    const { iconType, className } = props;
    const { icon, alt, hover } = SkillIcons[iconType];
    return <img src={icon} alt={alt} title={hover} className={`${className}`} />;
}