import { useEffect, useState } from "react";
import { TimelineEntry } from "../../types/timeline";
import './timeline-table.css';

export function TimelineTable() {
  const [timelineEntries, setTimelineEntries] = useState<
    [TimelineEntry] | null
  >();
  useEffect(() => {
    getTimelineEntries();
  }, []);

  const getTimelineEntries = async () => {
    const response = await fetch(
      "https://tlixt0dind.execute-api.eu-central-1.amazonaws.com/dev/timeline/"
    );
    const body = await response.json();
    const timelineEntries: [TimelineEntry] = body.Items;
    timelineEntries.sort(sortByStartingDate);
    timelineEntries.reverse();
    setTimelineEntries(timelineEntries);
  };

  function sortByStartingDate(a: TimelineEntry, b: TimelineEntry) {
    return new Date('01/' + a.TIMESPAN.split("-")[0]).getTime() - new Date('01/' + b.TIMESPAN.split("-")[0]).getTime();
  }

  return (
    <div className="admin-form-table">
      <h2>Timeline Content</h2>
      <table>
        <thead>
          <tr>
            <td>ID</td>
            <td>HEADING</td>
            <td>CONTENT</td>
            <td>TIMESPAN</td>
            <td>CREATION_DATE</td>
            <td>LANGUAGES AND FRAMEWORKS</td>
            <td>PLATFORMS</td>
            <td>TOOLS</td>
          </tr>
        </thead>
        <tbody>
          {timelineEntries?.map((item) => {
            return (
              <tr>
                <td>{item.ID}</td>
                <td>{item.HEADING}</td>
                <td>{item.CONTENT}</td>
                <td>{item.TIMESPAN}</td>
                <td>{item.CREATION_DATE}</td>
                <td>{item.LANGUAGES_AND_FRAMEWORKS}</td>
                <td>{item.PLATFORMS}</td>
                <td>{item.TOOLS}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
