import { ContentWrapper } from '../content-wrapper/content-wrapper';
import './footer.css';
import { HashLink } from 'react-router-hash-link';
import { Flex } from '../flex/flex';

export function Footer() {
    return (
        <div className="background-red">
            <ContentWrapper>
                <footer className="footer">
                    <Flex container={true}
                        responsiveReverse={false}
                        flexDirection="row"
                        justifyContent="space-between"
                        gap="1rem"
                        className="footer-container"
                    >
                        <div className="footer-box">
                            <a> © 2021 - <em>Andreas Lemberger</em></a>
                        </div>
                        <div className="footer-box">
                            <HashLink smooth to="/legal#top" className="footer-link">
                                <a>Legal Details</a>
                            </HashLink>
                        </div>
                        <div className="footer-box">
                            <HashLink smooth to="#top" className="footer-link">
                                <a> Back to top &uarr;</a>
                            </HashLink>
                        </div>
                    </Flex>
                </footer>
            </ContentWrapper>
        </div>
    )
}