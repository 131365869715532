import { Card } from "../components/card/card";
import { ContentWrapper } from "../components/content-wrapper/content-wrapper";
import { Footer } from "../components/footer/footer";
import { MainHeader } from "../components/main-header/main-header";
import './legal-details.css'

export function LegalDetails() {

    return <div><ContentWrapper>
        <MainHeader></MainHeader>
        <h1 className="legal-main-headline"> Legal Disclosure </h1>
        <div className="subheading red section-heading">Imprint</div>
        <Card className="card-white legal-card">
            <h3 className="legal-heading">
                Information in accordance with Section 5 TMG
            </h3>
            <div>
                Andreas Lemberger <br />
                Poststraße 10<br />
                70806 Kornwestheim
            </div>

        </Card>
        <Card className="card-white legal-card">
            <h3 className="legal-heading">
                Contact Information
            </h3>
            <div>
                Telephone: 015735511167 <br />
                E-Mail: me@andreas-lemberger.de
            </div>
        </Card>
        <div className="subheading red section-heading">Disclaimer</div>
        <Card className="card-white legal-card">
            <h3 className="legal-heading">
                Accountability for content
            </h3>
            <div>
                The contents of our pages have been created with the utmost care. However, we cannot guarantee
                the contents' accuracy, completeness or topicality. According to statutory provisions, we are
                furthermore responsible for our own content on these web pages. In this matter, please note that
                we are not obliged to monitor the transmitted or saved information of third parties, or
                investigate circumstances pointing to illegal activity. Our obligations to remove or block
                the use of information under generally applicable laws remain unaffected by this as per
                §§ 8 to 10 of the Telemedia Act (TMG).
            </div>
        </Card>
        <Card className="card-white legal-card">
            <h3 className="legal-heading">
                Accountability for links
            </h3>
            <div>
                Responsibility for the content of external links (to web pages of third parties) lies solely with
                the operators of the linked pages. No violations were evident to us at the time of linking. Should
                any legal infringement become known to us, we will remove the respective link immediately.
            </div>
        </Card>
        <Card className="card-white legal-card">
            <h3 className="legal-heading">
                Copyright
            </h3>
            <div>
                Our web pages and their contents are subject to German copyright law. Unless expressly permitted
                by law, every form of utilizing, reproducing or processing works subject to copyright protection
                on our web pages requires the prior consent of the respective owner of the rights. Individual
                reproductions of a work are only allowed for private use. The materials from these pages are
                copyrighted and any unauthorized use may violate copyright laws.
            </div>
        </Card>
        <div className="subheading red section-heading">Sources</div>
        <Card className="card-white legal-card">
            <h3 className="legal-heading">
                Text Sources
            </h3>
            <div>
                <a>Legal texts: </a>
                <a className="links" href="http://www.translate-24h.de" target="_blank">Übersetzungsbüro translate-24h.de</a> <br />
            </div>
        </Card>
        <Card className="card-white legal-card">
            <h3 className="legal-heading">
                Image Sources
            </h3>
            <div>
                <a>Jenkins Logo: </a>
                <a className="links" href="https://commons.wikimedia.org/wiki/File:Jenkins_logo.svg" target="_blank">Wikimedia Jenkins</a> <br />
                <a>Python Logo: </a>
                <a className="links" href="https://commons.wikimedia.org/wiki/File:Python-logo-notext.svg" target="_blank">Wikimedia Python</a> <br />
                <a>Linux Logo: </a>
                <a className="links" href="https://commons.wikimedia.org/wiki/File:Tux.svg" target="_blank">Wikimedia Tux Linux</a> <br />
                <a>Java Logo: </a><a className="links" href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a className="links" href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
            </div>
        </Card>
    </ContentWrapper >
        <Footer />
    </div>
}