import { Auth } from 'aws-amplify';
import * as React from 'react';
import './timeline-create.css';

export function TimelineCreate() {
    const [heading, setHeading] = React.useState("");
    const [content, setContent] = React.useState("");
    const [timespan, setTimespan] = React.useState("");
    const [languages, setLanguages] = React.useState("");
    const [platforms, setPlatforms] = React.useState("");
    const [tools, setTools] = React.useState("");

    const updateHeading = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHeading(event.target.value);
    }

    const updateContent = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setContent(event.target.value);
    }

    const updateTimespan = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTimespan(event.target.value);
    }

    const updateLanguages = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLanguages(event.target.value);
    }

    const updatePlatforms = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPlatforms(event.target.value);
    }

    const updateTools = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTools(event.target.value);
    }


    const submit = async (event: any) => {
        event.preventDefault();
        const body = {
            HEADING: heading,
            CONTENT: content,
            TIMESPAN: timespan,
            LANGUAGES_AND_FRAMEWORKS: languages,
            PLATFORMS: platforms,
            TOOLS: tools
        }
        console.log("body: ", body)
        const token = (await Auth.currentSession()).getIdToken();
        fetch("https://tlixt0dind.execute-api.eu-central-1.amazonaws.com/dev/timeline/create", {
            method: "POST",
            headers: {
                "Cognito-Token": token.getJwtToken(),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        })
    }

    return <div className="admin-form-create">
        <h2 >Create timeline entry</h2>
        <form onSubmit={submit}>
            <table>
                <tbody>
                    <tr>
                        <td><label> Heading of entry </label></td>
                        <td><input id="heading" className="input" onChange={updateHeading} placeholder='Some heading' /></td>
                    </tr>
                    <tr>
                        <td><label> Content of entry </label></td>
                        <td><textarea id="content" className="input-large input" onChange={updateContent} placeholder='The content' /></td>
                    </tr>
                    <tr>
                        <td><label> Timespan </label></td>
                        <td><input id="timespan" className="input" onChange={updateTimespan} placeholder='MM/YYYY-MM/YYYY' /></td>
                    </tr>
                    <tr>
                        <td><label> Languages and Frameworks</label></td>
                        <td><input id="languagesAndFrameworks" className="input" onChange={updateLanguages} placeholder='comma seperated list' /></td>
                    </tr>
                    <tr>
                        <td><label> Platforms </label></td>
                        <td><input id="platforms" className="input" onChange={updatePlatforms} placeholder='comma seperated list' /></td>
                    </tr>
                    <tr>
                        <td><label> Tools </label></td>
                        <td><input id="tools" className="input" onChange={updateTools} placeholder='comma seperated list' /></td>
                    </tr>
                </tbody>
            </table>
            <button className="button" type='submit'>Submit</button>
        </form>
    </div>
}