import { ContentWrapper } from "../content-wrapper/content-wrapper";
import './about-me.css'
import me from '../../assets/me.png';
import { IconProvider } from "../icon-provider/icon-provider";
import { Flex } from "../flex/flex";
import { useEffect } from "react";
import gsap from "gsap/all";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


export function AboutMe() {

    useEffect(() => {
        const mq = window.matchMedia('(max-width: 1000px)');

        if (mq.matches) {
            mobileAnimations();
        } else {
            defaultAnimations();
        }
    }, [])

    const defaultAnimations = () => {
        gsap.from('#about-heading', {
            duration: 1,
            y: '-20',
            opacity: 0,
            ease: 'ease-in',
            scrollTrigger: {
                trigger: '#about-heading',
                start: 'top 74%',
            },
        });

        gsap.from('.about-container', {
            duration: 1,
            y: '40',
            opacity: 0,
            ease: 'ease-in',
            scrollTrigger: {
                trigger: '.about-container',
                start: 'top 74%',
            },
        });

        gsap.from('#about-headline', {
            duration: 0.5,
            y: '30',
            opacity: 0,
            ease: 'ease-in',
            scrollTrigger: {
                trigger: '#about-headline',
                start: 'top 71%',
            },
        });

        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: '#about-text-1',
                start: 'top 68%',
            }
        });


        tl.from('#about-text-1',
            { opacity: 0, duration: 0.4, y: 20 }
        ).from("#about-text-2",
            { opacity: 0, duration: 0.4, y: 20 }
        ).from(".about-box-picture",
            { opacity: 0, duration: 0.4, y: 20 });


        const tlIcons = gsap.timeline({
            scrollTrigger: {
                trigger: '#about-text-1',
                start: 'top 67%',
            }
        });


        tlIcons.from('#about-social-1',
            { opacity: 0, duration: 0.4, y: 30 }
        ).from("#about-social-2",
            { opacity: 0, duration: 0.4, y: 30 }
        ).from("#about-social-3",
            { opacity: 0, duration: 0.4, y: 30 });
    }

    const mobileAnimations = () => {
        gsap.from('#about-heading', {
            duration: 1,
            y: '-20',
            opacity: 0,
            ease: 'ease-in',
            scrollTrigger: {
                trigger: '#about-heading',
                start: 'top 74%',
            },
        });

        gsap.from('.about-container', {
            duration: 1,
            y: '40',
            opacity: 0,
            ease: 'ease-in',
            scrollTrigger: {
                trigger: '.about-container',
                start: 'top 75%',
            },
        });

        gsap.from('.about-box-picture', {
            duration: 0.5,
            y: '30',
            opacity: 0,
            ease: 'ease-in',
            scrollTrigger: {
                trigger: '.about-box-picture',
                start: 'top 74%',
            },
        });

        gsap.from('#about-headline', {
            duration: 0.5,
            y: '30',
            opacity: 0,
            ease: 'ease-in',
            scrollTrigger: {
                trigger: '#about-headline',
                start: 'top 86%',
            },
        });

        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: '#about-text-1',
                start: 'top 70%',
            }
        });

        tl.from('#about-text-1',
            { opacity: 0, duration: 0.5, y: 20 }
        ).from("#about-text-2",
            { opacity: 0, duration: 0.5, y: 20 }
        );

        const tlIcons = gsap.timeline({
            scrollTrigger: {
                trigger: '#about-text-1',
                start: 'top 50%',
            }
        });

        tlIcons.from('#about-social-1',
            { opacity: 0, duration: 0.4, y: 30 }
        ).from("#about-social-2",
            { opacity: 0, duration: 0.4, y: 30 }
        ).from("#about-social-3",
            { opacity: 0, duration: 0.4, y: 30 });
    }

    return <ContentWrapper id="about">

        <div id="about-heading" className="subheading red section-heading">About me</div>

        <Flex container={true}
            responsiveReverse={true}
            flexDirection="row"
            justifyContent="space-around"
            margin="3rem 0rem 0rem 0rem"
            alignItems="center"
            gap="3rem"
            className="about-container"
        >
            <div className="about-box-text">
                <div>
                    <h3 id="about-headline">Welcome!</h3>
                    <p id="about-text-1">I'm Andreas, a computer scientist currently working as a Full Stack Cloud Developer &amp; Cloud Engineer. I'm living in a small city near Stuttgart, Germany. </p>
                    <p id="about-text-2">After finishing my bachelor's degree in business informatics in 2017, I started out as a consultant. I was part of several projects for customers in
                        different industries, mostly in the role of a software developer. As I have always been very interested in cloud platforms,
                        I increasingly put my focus on cloud development. Since then, I have worked on multiple cloud native products and projects and developed into my current role.
                        What I enjoy most about cloud technologies and programming are the limitless possibilities to solve problems and learn new things. That's why I'm still actively working
                        on extending my knowledge every day - for example with projects such as this site.
                    </p>
                </div>

                <Flex container={true}
                    flexDirection="row"
                    justifyContent="space-between"
                    padding="1.5rem 0rem 0rem 0rem"
                >
                    <a id="about-social-1" href="https://www.xing.com/profile/Andreas_Lemberger2/cv" target="_blank" rel="noopener noreferrer">
                        <IconProvider iconType="xing" className="social-icon" />
                    </a>
                    <a id="about-social-2" href="https://www.linkedin.com/in/andreas-lemberger-951907205/" target="_blank" rel="noopener noreferrer">
                        <IconProvider iconType="linkedin" className="social-icon" />
                    </a>
                    <a id="about-social-3" href="https://github.com/andreaslemberger" target="_blank" rel="noopener noreferrer">
                        <IconProvider iconType="github" className="social-icon" />
                    </a>
                </Flex>
            </div>

            <div className="about-box-picture">
                <img className="picture" src={me} alt="me"></img>
            </div>

        </Flex>


    </ContentWrapper>

}

